import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js' // Import useStore hook
import { t, Trans } from '@lingui/macro'
import { SocialIcon } from 'react-social-icons'

import Popover from '@material-ui/core/Popover'
import { MyButton } from '../_MyComponents'
import Snackbar from '@material-ui/core/Snackbar'

import { socialHost } from '../../services/host'
import './style.scss'

const SocialShare = observer(
  ({
    // from parent
    fileId,
    open,
    anchorEl,
    setAnchorEl,
    onClose,
    file,
  }) => {
    const { filesStore } = useStore()

    const { createShare, currentCollection } = filesStore

    const [socialURL, setSocialURL] = useState('')
    const [urlSnackbarOpen, setUrlSnackbarOpen] = useState(false)

    const copyUrl = () => {
      setUrlSnackbarOpen(true)
      navigator.clipboard.writeText(socialURL)
    }

    const closeUrlSnackbar = (e, reason) => {
      if (reason === 'clickaway') {
        return
      }
      setUrlSnackbarOpen(false)
    }

    const handleShare = async () => {
      const share = await createShare({
        fileId,
        collectionId: currentCollection?._id,
      })
      console.log('Share:', share)

      const url = `${socialHost}/share/${share.shortId}`
      console.log('URL:', url)

      setSocialURL(url)
      return url
    }

    useEffect(() => {
      if (open) {
        handleShare()
      }
    }, [open])

    const handleClose = () => {
      setAnchorEl(null)
      onClose()
    }

    // create a share to social media using their API

    const shareOnFacebook = async () => {
      let useUrl = socialURL
      if (socialURL === '') {
        useUrl = await handleShare()
      }
      useUrl = encodeURIComponent(useUrl)
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${useUrl}`
      window.open(facebookShareUrl, '_blank', 'noopener,noreferrer')
    }

    const shareOnTwitter = async () => {
      let useUrl = socialURL
      if (socialURL === '') {
        useUrl = await handleShare()
      }
      useUrl = encodeURIComponent(useUrl)
      const twitterShareUrl = `https://twitter.com/intent/tweet?url=${useUrl}`
      window.open(twitterShareUrl, '_blank')
    }

    const shareOnLinkedIn = async () => {
      let useUrl = socialURL
      if (socialURL === '') {
        useUrl = await handleShare()
      }
      useUrl = encodeURIComponent(useUrl)
      const linkedInShareUrl = `https://www.linkedin.com/shareArticle?url=${useUrl}`
      window.open(linkedInShareUrl, '_blank')
    }

    const shareOnWhatsApp = async () => {
      let useUrl = socialURL
      if (socialURL === '') {
        useUrl = await handleShare()
      }
      useUrl = encodeURIComponent(useUrl)
      const whatsAppShareUrl = `https://api.whatsapp.com/send?text=${useUrl}`
      window.open(whatsAppShareUrl, '_blank')
    }

    return (
      <Popover
        id={fileId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className="test"
      >
        {/* add icons for facebook, instagram, linkedin, whatsapp */}
        <div className="social-share-wrapper">
          <div className="social-share-title">
            <h2 className="h2">Share to social media</h2>
          </div>
          <div className="social-share-buttons">
            <div className="social-share-item" onClick={shareOnTwitter}>
              <SocialIcon network="x" />
              <p className="p">x.com</p>
            </div>
            <div className="social-share-item" onClick={shareOnFacebook}>
              <SocialIcon network="facebook" />
              <p className="p">Facebook</p>
            </div>
            {/* <div className="social-share-item" onClick={shareOnInstagram}>
              <SocialIcon url="https://instagram.com" />
              <p className="p">Instagram</p>
            </div> */}
            <div className="social-share-item" onClick={shareOnLinkedIn}>
              <SocialIcon network="linkedin" />
              <p className="p">LinkedIn</p>
            </div>
            <div className="social-share-item" onClick={shareOnWhatsApp}>
              <SocialIcon network="whatsapp" />
              <p className="p">WhatsApp</p>
            </div>
          </div>
          <div className="copy-url">
            <MyButton
              className={'url'}
              disableRipple
              onClick={copyUrl}
              //disabled={!shareToggle}
            >
              {socialURL}
            </MyButton>
            <MyButton
              onClick={copyUrl}
              //disabled={!shareToggle}
              variant="contained"
              color="secondary"
            >
              <Trans>Copy</Trans>
            </MyButton>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={urlSnackbarOpen}
          autoHideDuration={4000}
          onClose={closeUrlSnackbar}
          message="Link copied!"
        />
      </Popover>
    )
  }
)

export default SocialShare
