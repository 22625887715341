import axios from 'axios'
import { host } from '../../host'
import { getHeaderMerged } from '../util'
import { getQueryParam } from '../../../util/httpHelper'

export const createShare = async ({
  fileId,
  collectionId,
  workspaceId,
  orgId,
}) => {
  const url = `${host}/share/create`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { fileId, collectionId, workspaceId, orgId },
    })
    return data
  } catch (err) {
    throw err
  }
}
