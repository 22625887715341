export const URLS = {
  PRODUCTION_HOST: 'https://api.tagbox.io',
  STAGING_HOST: 'https://api-staging.tagbox.io',
  DEVELOPMENT_HOST: 'https://dev-api.tagbox.io',
  LOCAL_HOST: 'https://dev-api.tagbox.io',
  //LOCAL_HOST: 'http://localhost:3001',
}

export const SYNC_URLS = {
  PRODUCTION_HOST: 'https://api-sync.tagbox.io',
  STAGING_HOST: 'https://api-staging-sync.tagbox.io',
  DEVELOPMENT_HOST: 'https://dev-api-sync.tagbox.io',
  LOCAL_HOST: 'https://dev-api-sync.tagbox.io',
  //LOCAL_HOST: 'http://localhost:3001',
}

export const SOCIAL_URLS = {
  PRODUCTION_HOST: 'https://social.tagbox.io',
  STAGING_HOST: 'https://social-staging.tagbox.io',
  DEVELOPMENT_HOST: 'https://social-dev.tagbox.io',
  LOCAL_HOST: 'https://social-dev.tagbox.io',
  //LOCAL_HOST: 'http://localhost:3001',
}

export const SERVERLESS_URLS = {
  PRODUCTION_HOST:
    'https://7wjuvec5u7.execute-api.eu-west-1.amazonaws.com/prod',
  STAGING_HOST:
    'https://iekwyq52n4.execute-api.eu-west-1.amazonaws.com/staging',
  DEVELOPMENT_HOST:
    'https://lrlyw095i0.execute-api.eu-west-1.amazonaws.com/dev',
  LOCAL_HOST: 'https://lrlyw095i0.execute-api.eu-west-1.amazonaws.com/dev',
}

export const CLIENT_URLS = {
  PRODUCTION_HOST: 'https://app.tagbox.io',
  STAGING_HOST: 'https://staging.tagbox.io',
  DEVELOPMENT_HOST: 'https://develop.tagbox.io',
  LOCAL_HOST: 'http://localhost:3000',
}

export const METABASE_URLS = {
  PRODUCTION_HOST:
    'https://metabase.tagbox-internal.com/public/dashboard/e26a9eab-370c-4b38-82a8-0592ec1d7a3d',
  STAGING_HOST:
    'https://metabase.tagbox-internal.com/public/dashboard/e26a9eab-370c-4b38-82a8-0592ec1d7a3d',
  DEVELOPMENT_HOST:
    'https://metabase.tagbox-internal.com/public/dashboard/e26a9eab-370c-4b38-82a8-0592ec1d7a3d',
  LOCAL_HOST:
    'https://metabase.tagbox-internal.com/public/dashboard/e26a9eab-370c-4b38-82a8-0592ec1d7a3d',
}
