import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { t } from '@lingui/macro'
import './LangChange.scss'

import { i18n } from '@lingui/core'
import { messages as HE } from '../../locales/he/messages'
import { messages as EN } from '../../locales/en/messages'
import { messages as PT } from '../../locales/pt/messages'
import { messages as ES } from '../../locales/es/messages'
import { messages as FR } from '../../locales/fr/messages'

import ActionDialog from '../ActionDialog/ActionDialog.js'

const LangChange = observer(
  (
    {
      // from parent
    }
  ) => {
    const { filesStore, authenticationStore } = useStore()

    const { setLanguage } = filesStore
    const { userState, updateUserState, isAnonymous } = authenticationStore

    const [osLanguage, setOsLanguage] = useState('')
    const [langDialog, setLangDialog] = useState(false)

    ///////////////////////////////////////////////////
    // DETECT LANGAUGE
    //////////////////////////////////////////////////

    const supportedLanguages = ['en', 'es', 'fr', 'pt', 'he']

    const detectLanguage = () => {
      const lang = navigator.language || navigator.userLanguage
      //check if the base language is in supported languages
      if (supportedLanguages.includes(lang.split('-')[0])) {
        return lang
      }
      return 'en-US'
    }

    // hard-coded texts using in the language popup
    const langStrings = {
      es: {
        english: 'Would you like to use Tagbox.io in Spanish?',
        question: '¿Te gustaría usar Tagbox.io en español?',
        useIn: 'Usar en español',
      },
      fr: {
        english: 'Would you like to use Tagbox.io in French?',
        question: 'Souhaitez-vous utiliser Tagbox.io en français ?',
        useIn: 'Utiliser en français',
      },
      pt: {
        english: 'Would you like to use Tagbox.io in Portuguese?',
        question: 'Gostaria de usar o Tagbox.io em português?',
        useIn: 'Usar em português',
      },
      he: {
        english: 'Would you like to use Tagbox.io in Hebrew?',
        question: 'האם תרצה להשתמש ב-Tagbox.io בעברית?',
        useIn: 'השתמש בעברית',
      },
    }

    const changeLanguage = (lang) => {
      const messages =
        lang === 'he-IL'
          ? HE
          : lang === 'pt-BR'
          ? PT
          : lang === 'es-ES'
          ? ES
          : lang === 'fr-FR'
          ? FR
          : EN
      setLanguage(lang)
      i18n.load(lang, messages)
      i18n.activate(lang)
    }

    useEffect(() => {
      if (!isAnonymous && userState) {
        if (!!userState.language && userState.language !== 'en-US') {
          changeLanguage(userState.language)
        } else if (!!userState.language) {
          //if the user has a language just return
          return
        } else {
          const lang = detectLanguage()
          debugger
          setOsLanguage(lang)
          //if the language is not english, show the language dialog
          const otherSupportedLanguages = ['es', 'fr', 'pt'] //, 'he']
          if (otherSupportedLanguages.includes(lang.split('-')[0])) {
            setLangDialog(true)
          }
        }
      }
    }, [userState, userState?.language])

    const handleLangChange = (lang) => {
      const shortLang = lang.split('-')[0]
      const fullLang =
        shortLang === 'he'
          ? 'he-IL'
          : shortLang === 'pt'
          ? 'pt-BR'
          : shortLang === 'es'
          ? 'es-ES'
          : shortLang === 'fr'
          ? 'fr-FR'
          : 'en-US'
      updateUserState({ language: fullLang })
      changeLanguage(fullLang)
      setLangDialog(false)
    }

    return (
      <>
        <ActionDialog
          question={t`Change Language?`}
          comments={[
            langStrings[osLanguage.split('-')[0]]?.english,
            langStrings[osLanguage.split('-')[0]]?.question,
          ]}
          isOpen={langDialog}
          actionName={langStrings[osLanguage.split('-')[0]]?.useIn}
          cancelName={t`Use in English`}
          action={() => handleLangChange(osLanguage)}
          cancel={() => handleLangChange('en-US')}
        />
      </>
    )
  }
)

export default LangChange
