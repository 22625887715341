import {
  URLS,
  SYNC_URLS,
  SERVERLESS_URLS,
  CLIENT_URLS,
  METABASE_URLS,
  SOCIAL_URLS,
} from '../util/urls'

export const host =
  {
    production: URLS.PRODUCTION_HOST,
    staging: URLS.STAGING_HOST,
    development: URLS.DEVELOPMENT_HOST,
    local: URLS.LOCAL_HOST,
  }[process.env.REACT_APP_NODE_ENV] || URLS.LOCAL_HOST

export const syncHost =
  {
    production: SYNC_URLS.PRODUCTION_HOST,
    staging: SYNC_URLS.STAGING_HOST,
    development: SYNC_URLS.DEVELOPMENT_HOST,
    local: SYNC_URLS.LOCAL_HOST,
  }[process.env.REACT_APP_NODE_ENV] || SYNC_URLS.LOCAL_HOST

export const socialHost =
  {
    production: SOCIAL_URLS.PRODUCTION_HOST,
    staging: SOCIAL_URLS.STAGING_HOST,
    development: SOCIAL_URLS.DEVELOPMENT_HOST,
    local: SOCIAL_URLS.LOCAL_HOST,
  }[process.env.REACT_APP_NODE_ENV] || SOCIAL_URLS.LOCAL_HOST

export const serverlessHost =
  {
    production: SERVERLESS_URLS.PRODUCTION_HOST,
    staging: SERVERLESS_URLS.STAGING_HOST,
    development: SERVERLESS_URLS.DEVELOPMENT_HOST,
    local: SERVERLESS_URLS.LOCAL_HOST,
  }[process.env.REACT_APP_NODE_ENV] || SERVERLESS_URLS.LOCAL_HOST

export const client =
  {
    production: CLIENT_URLS.PRODUCTION_HOST,
    staging: CLIENT_URLS.STAGING_HOST,
    development: CLIENT_URLS.DEVELOPMENT_HOST,
    local: CLIENT_URLS.LOCAL_HOST,
  }[process.env.REACT_APP_NODE_ENV] || CLIENT_URLS.LOCAL_HOST

export const metabase =
  {
    production: METABASE_URLS.PRODUCTION_HOST,
    staging: METABASE_URLS.STAGING_HOST,
    development: METABASE_URLS.DEVELOPMENT_HOST,
    local: METABASE_URLS.LOCAL_HOST,
  }[process.env.REACT_APP_NODE_ENV] || METABASE_URLS.LOCAL_HOST
