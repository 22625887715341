import React, { useState } from 'react'
import './InviteUsers.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'

import { t, Trans } from '@lingui/macro'

import { filter } from 'lodash'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'

import { MyButton } from '../_MyComponents'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { isEmail } from 'validator'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'

const InviteUsers = observer(
  ({
    // from parent
    setUpgradeDialog,
    setDialogState,
    setSidemenuState,
    inviteFunction,
    users,
  }) => {
    const { orgsWorkspacesStore, authenticationStore } = useStore()

    const {
      ORG_ROLES,
      workspaceUsers,
      updateUserIsRole,

      orgPlanLimits,
    } = orgsWorkspacesStore

    const { username } = authenticationStore

    const [emails, setEmails] = useState([])
    const [role, setRole] = useState(Object.values(ORG_ROLES)[2])
    const [errorMessage, setErrorMessage] = useState('')
    const [emailFilter, setEmailFilter] = useState('')
    const [emailInputValue, setEmailInputValue] = useState('')
    const [isInputValidEmail, setIsInputValidEmail] = useState(false) // Tracks validity of input

    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault()
        const trimmedValue = emailInputValue.trim()
        if (trimmedValue && isInputValidEmail) {
          addEmail(trimmedValue) // Add the email
          setEmailInputValue('') // Clear the input
          setIsInputValidEmail(false) // Reset email validity
        }
      }
    }

    const handleInputChange = (event, newValue) => {
      setEmailInputValue(newValue) // Regular updates for deletions or typing

      // Check if the current input is a valid email
      if (isEmail(newValue.trim())) {
        setIsInputValidEmail(true)
      } else {
        setIsInputValidEmail(false)
      }
    }

    const addEmail = (email) => {
      if (
        //studio and free plans have a limit of 3 users
        orgPlanLimits.userCountLimit > 0 &&
        workspaceUsers.length + emails.length >= orgPlanLimits.userCountLimit
      ) {
        setUpgradeDialog(true)
      } else {
        if (!isEmail(email)) {
          setErrorMessage(`Invalid email '${email}'`)
        } else {
          setErrorMessage('')
          setEmails([...emails, email])
        }
      }
    }

    //////////////////////////
    // Add multiple emails using paste
    //////////////////////////

    const addEmails = (newEmails) => {
      //studio and free plans have a limit of 3 users
      if (
        orgPlanLimits.userCountLimit > 0 &&
        workspaceUsers.length + emails.length + newEmails.length >=
          orgPlanLimits.userCountLimit
      ) {
        setUpgradeDialog(true)
      } else {
        var validEmails = []
        for (let email of newEmails) {
          if (!isEmail(email)) {
            setErrorMessage(`Invalid email '${email}'`)
          } else {
            setErrorMessage('')
            validEmails.push(email.toLowerCase())
          }
        }
        setEmails([...emails, ...validEmails])
      }
    }

    //handle paste on chip input
    const handlePaste = (event) => {
      const clipboardData = event.clipboardData
      const pastedText = clipboardData.getData('text')
      const separators = [',', ' ', ';', '\n', '\t', '\r', '\r\n']

      // Split pasted text using separators
      const chips = pastedText.split(new RegExp(`[${separators.join('')}]`))
      if (chips.length > 1) {
        event.preventDefault() // prevent the default paste behavior - so input value is not pasted
        addEmails(chips)
      } else {
        //do nothing
        return
      }
    }

    //////////////////////////

    const deleteEmail = (email) => {
      setErrorMessage('')
      if (email) {
        setEmails(filter(emails, (e) => e !== email))
      }
    }

    const deleteUser = (obj) => {
      setDialogState(obj)
    }

    const handleChangeRole = (ev) => {
      setRole(ev.target.value)
    }

    const membersSec = (
      <div className="settings-section">
        <h2 className="h2">
          <Trans>Members</Trans>
        </h2>
        <div className="members">
          <div className="add-user-box">
            <Autocomplete
              multiple
              className="custom-autocomplete"
              freeSolo
              value={emails}
              onChange={(event, newValue, reason) => {
                if (reason === 'createOption') {
                  // Handle adding new email
                  addEmail(newValue[newValue.length - 1])
                } else if (reason === 'removeOption') {
                  // Handle deleting an email
                  const removedEmail = emails.find(
                    (email) => !newValue.includes(email)
                  )
                  if (removedEmail) {
                    deleteEmail(removedEmail)
                  }
                }
              }}
              inputValue={emailInputValue}
              onInputChange={handleInputChange}
              options={[]} // No predefined options as this is freeform input
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    variant="outlined"
                    label={option}
                    color="primary"
                    {...getTagProps({ index })}
                    className="custom-chip"
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  className="custom-input"
                  onPaste={handlePaste}
                  {...params}
                  variant="standard"
                  placeholder={t`Add an email and click 'Enter'`}
                  //error={Boolean(errorMessage)}
                  //helperText={errorMessage}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  onKeyDown={handleKeyDown}
                />
              )}
            />
            <FormControl variant="outlined" className="select-input">
              <Select value={role} onChange={handleChangeRole}>
                {Object.values(ORG_ROLES)
                  .filter(
                    (role) => !role.toLowerCase().startsWith('collection')
                  ) //remove collection roles
                  .map((role) => (
                    <MenuItem key={role} value={role}>
                      <span style={{ textTransform: 'capitalize' }}>
                        {role}
                      </span>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <MyButton
              variant="contained"
              color="primary"
              className="invite-button"
              disabled={!isInputValidEmail && !emails.length} // Enable only if input is a valid email or emails exist
              onClick={() => {
                const emailToSend = emailInputValue.trim()
                if (isInputValidEmail && emailToSend) {
                  inviteFunction({
                    emails: [...emails, emailToSend],
                    role,
                  }).then(() => {
                    setEmails([]) // Clear the email list
                  })
                  setEmailInputValue('') // Clear the input
                  setIsInputValidEmail(false) // Reset email validity
                } else if (emails.length) {
                  inviteFunction({ emails, role }).then(() => {
                    setEmails([]) // Clear the email list
                  })
                }
              }}
            >
              <Trans>Invite</Trans>
            </MyButton>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
          </div>
          <div
            className="read-more"
            onClick={() => setSidemenuState('permissions')}
          >
            <Trans>Read more about Roles & Permissions</Trans>
          </div>
          <div className="members-list-wrapper">
            <div className="members-list-title">
              <Trans>Members</Trans>
            </div>
            <div className="email-filter">
              <TextField
                variant="outlined"
                onChange={(e) => setEmailFilter(e.target.value.toLowerCase())}
                size="small"
                placeholder={t`Filter by email`}
                className={'delete-workspace-validation'}
                value={emailFilter}
              />
            </div>
            <div className="members-list">
              {users
                ?.filter(
                  (user) =>
                    user.userId.email.includes(emailFilter) &&
                    user.role !== 'collectionUser'
                )
                ?.map(({ userId, workspaceId, _id, role }, index) => (
                  <div key={index} className="members-list-names">
                    <div className={'p name'}>{userId.username}</div>
                    <FormControl
                      className="members-select-input"
                      variant="outlined"
                    >
                      <Tooltip
                        enterDelay={500}
                        title={
                          userId.username === username
                            ? t`You can’t change your own permissions`
                            : ''
                        }
                      >
                        <Select
                          disabled={userId.username === username}
                          value={role}
                          onChange={(event) => {
                            const user = workspaceUsers.find(
                              (user) => userId.username === user.userId.username
                            )
                            if (user) {
                              updateUserIsRole({
                                userId: user.userId._id,
                                role: event.target.value,
                              })
                            }
                          }}
                        >
                          {Object.values(ORG_ROLES)
                            .filter(
                              (role) =>
                                !role.toLowerCase().startsWith('collection')
                            ) //remove collection roles
                            .map((role) => (
                              <MenuItem
                                key={`${username}-${role}`}
                                value={role}
                              >
                                <span style={{ textTransform: 'capitalize' }}>
                                  {role}
                                </span>
                              </MenuItem>
                            ))}{' '}
                        </Select>
                      </Tooltip>
                    </FormControl>

                    {
                      <IconButton
                        className={`member-delete ${
                          userId.username === username && 'invisible'
                        }`}
                        size="small"
                        aria-label="upload picture"
                        component="span"
                        onClick={() =>
                          deleteUser({ username: userId.username, userId })
                        }
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                  </div>
                ))}
              {users
                ?.filter(
                  (user) =>
                    user.userId.email.includes(emailFilter) &&
                    user.role === 'collectionUser'
                )
                .map(({ userId, workspaceId, _id, role }, index) => (
                  <div key={index} className="members-list-names">
                    <div className={'p name'}>{userId.username}</div>
                    <FormControl
                      className="members-select-input"
                      variant="outlined"
                    >
                      {
                        <Tooltip
                          enterDelay={500}
                          title={t`You can't change permissions for a collection
                              user. Add them as workspace users to give them
                              full permissions.`}
                        >
                          <Select disabled value={'collectionUser'}>
                            {
                              <MenuItem
                                key={`${username}-${role}`}
                                value={'collectionUser'}
                              >
                                <span style={{ textTransform: 'capitalize' }}>
                                  <Trans>Collection user</Trans>
                                </span>
                              </MenuItem>
                            }
                          </Select>
                        </Tooltip>
                      }
                    </FormControl>

                    {userId.username !== username && (
                      <IconButton
                        className={'member-delete'}
                        size="small"
                        aria-label="upload picture"
                        component="span"
                        onClick={() =>
                          deleteUser({ username: userId.username, userId })
                        }
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    )

    return <>{membersSec}</>
  }
)

export default InviteUsers
