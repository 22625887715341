import React, { useEffect, useState, useRef } from 'react'
import { t, Trans } from '@lingui/macro'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { MyButton } from '../_MyComponents'
import LinearProgress from '@mui/material/LinearProgress'
import EVENTS from '../../assets/SurveyAssets/events.jpg'
import PRODUCTS from '../../assets/SurveyAssets/products.jpg'
import HOSPITALITY from '../../assets/SurveyAssets/hospitality.jpg'
import RESTAURANTS from '../../assets/SurveyAssets/restaurants.jpg'
import TRAVEL from '../../assets/SurveyAssets/travel.jpg'
import COLLECTION from '../../assets/collection-icon.svg'
import POSTER from '../../assets/SurveyAssets/clutter-free.svg'
import LOGO from '../../assets/logo.svg'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from '../_MyComponents'
import CircularProgress from '@material-ui/core/CircularProgress'
import './StartSurvey.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import LangChange from '../LangChange/LangChange.js'

const StartSurvey = observer(
  (
    {
      // from parent
    }
  ) => {
    const { orgsWorkspacesStore } = useStore()

    const { updateOrganization, addWorkspaceTemplate, setWorkspaceDefault } =
      orgsWorkspacesStore

    const [step, setStep] = useState(0)
    const [selected, setSelected] = useState(null)
    const [answers, setAnswers] = useState([{}])
    const [runSpinner, setRunSpinner] = useState(false)

    let info = {
      orgEntity: 'personal',
      orgSize: '0',
      orgObjective: 'both',
      orgAssetsType: 'other',
    }

    const q1Options = [
      { id: 'company', text: <Trans>For my company</Trans> },
      { id: 'nonprofit', text: <Trans>For my nonprofit</Trans> },
      { id: 'personal', text: <Trans>For personal use</Trans> },
    ]

    const q2Options = [
      { id: '1-10', text: '1-10' },
      { id: '11-50', text: '11-50' },
      { id: '51-150', text: '51-150' },
      { id: '151-500', text: '151-500' },
      { id: '500+', text: '500+' },
    ]

    const q3Options = [
      { id: 'internal', text: <Trans>Organize internally</Trans> },
      { id: 'external', text: <Trans>Share assets externally</Trans> },
      { id: 'both', text: <Trans>Both</Trans> },
    ]

    const q4Options = [
      { id: 'events', text: <Trans>Events & people</Trans>, image: EVENTS },
      { id: 'products', text: <Trans>products</Trans>, image: PRODUCTS },
      {
        id: 'hospitality',
        text: <Trans>Hospitality & real estate</Trans>,
        image: HOSPITALITY,
      },
      {
        id: 'restaurants',
        text: <Trans>Restaurants</Trans>,
        image: RESTAURANTS,
      },
      { id: 'travel', text: <Trans>Travel</Trans>, image: TRAVEL },
      {
        id: 'other',
        text: <Trans>Other (start from scratch)</Trans>,
        image: COLLECTION,
      },
    ]

    const handleChange = (id) => {
      setSelected(id)
    }

    const nextStep = async () => {
      const newAnswers = [...answers]
      newAnswers[step] = selected
      setAnswers(newAnswers)
      if (step < 3) {
        if (step === 0 && selected === 'personal') {
          info.orgEntity = 'personal'
          info.orgSize = '0'
          setStep(2)
        } else {
          setStep((prev) => prev + 1)
        }
      } else {
        info.orgEntity = answers[0] || 'personal'
        info.orgSize = answers[1] || '0'
        info.orgObjective = answers[2] || 'both'
        info.orgAssetsType = selected || 'other'
        updateOrganization({ update: { info } })
        if (info.orgAssetsType != 'other') {
          setRunSpinner(true)
          await addWorkspaceTemplate(info.orgAssetsType)
          setRunSpinner(false)
        }
        setWorkspaceDefault()
      }
      setSelected(null)
    }

    return (
      <MuiThemeProvider theme={theme}>
        {runSpinner ? (
          <div className="loading-spinner">
            {/* change background color to gray */}
            <CircularProgress color="secondary" size="1rem" />
          </div>
        ) : (
          <div className="survey-wrapper">
            <div className="survey-container">
              <div className="survey-header">
                <img src={LOGO} alt="tagbox-logo" />
              </div>
              <div className="survey-content">
                <div className="progress-bar">
                  <LinearProgress
                    variant="determinate"
                    style={{ color: '#FBC02D' }}
                    color="inherit"
                    value={((step + 1) / 4) * 100}
                  />
                </div>

                {/* Question 1 */}

                {step === 0 && (
                  <div className="question-container">
                    <div className="question-wrapper">
                      <h1 className="h1">
                        <Trans>
                          Welcome! How would you like to use Tagbox?
                        </Trans>
                      </h1>
                      <p className="p light">
                        <Trans>This helps customize your experience</Trans>
                      </p>
                    </div>
                    <div className="options-wrapper">
                      {q1Options.map((option) => (
                        <div
                          key={option.id}
                          className={`option ${
                            selected === option.id ? 'selected' : ''
                          }`}
                          onClick={() => handleChange(option.id)}
                        >
                          <input
                            type="radio"
                            id={`radio-${option.id}`}
                            name="image-radio"
                            value={option.id}
                            checked={selected === option.id}
                            onChange={() => handleChange(option.id)}
                            style={{ marginRight: '10px' }}
                          />
                          <label htmlFor={`radio-${option.id}`}>
                            <p className="p">{option.text}</p>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Question 2 */}

                {step === 1 && (
                  <div className="question-container">
                    <div className="question-wrapper">
                      <h1 className="h1">
                        <Trans>What’s your company’s size?</Trans>
                      </h1>
                      <p className="p light"></p>
                    </div>
                    <div className="options-wrapper vertical">
                      {q2Options.map((option) => (
                        <div
                          key={option.id}
                          className={`option vertical ${
                            selected === option.id ? 'selected' : ''
                          }`}
                          onClick={() => handleChange(option.id)}
                        >
                          <input
                            type="radio"
                            id={`radio-${option.id}`}
                            name="image-radio"
                            value={option.id}
                            checked={selected === option.id}
                            onChange={() => handleChange(option.id)}
                            style={{ marginRight: '10px' }}
                          />
                          <label htmlFor={`radio-${option.id}`}>
                            <p className="p">{option.text}</p>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Question 3 */}

                {step === 2 && (
                  <div className="question-container">
                    <div className="question-wrapper">
                      <h1 className="h1">
                        <Trans>What’s your main objective?</Trans>
                      </h1>
                      <p className="p light">
                        <Trans>
                          Your choice won’t limit your options later.
                        </Trans>
                      </p>
                    </div>
                    <div className="options-wrapper">
                      {q3Options.map((option) => (
                        <div
                          key={option.id}
                          className={`option ${
                            selected === option.id ? 'selected' : ''
                          }`}
                          onClick={() => handleChange(option.id)}
                        >
                          <input
                            type="radio"
                            id={`radio-${option.id}`}
                            name="image-radio"
                            value={option.id}
                            checked={selected === option.id}
                            onChange={() => handleChange(option.id)}
                            style={{ marginRight: '10px' }}
                          />
                          <label htmlFor={`radio-${option.id}`}>
                            <p className="p">{option.text}</p>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Question 4 */}

                {step === 3 && (
                  <div className="question-container">
                    <div className="question-wrapper">
                      <h1 className="h1">
                        <Trans>Which best describes your assets?</Trans>
                      </h1>
                      <p className="p light">
                        <Trans>This helps us set up your workspace.</Trans>
                      </p>
                    </div>
                    <div className="options-wrapper grid">
                      {q4Options.map((option) => (
                        <div
                          key={option.id}
                          className={`image-option`}
                          onClick={() => handleChange(option.id)}
                        >
                          <div
                            className={`image-container ${
                              selected === option.id ? 'selected' : ''
                            }`}
                          >
                            <img src={option.image} alt={option.text} />
                            <input
                              type="radio"
                              id={`radio-${option.id}`}
                              name="image-radio"
                              value={option.id}
                              checked={selected === option.id}
                              onChange={() => handleChange(option.id)}
                              style={{ marginRight: '10px' }}
                            />
                          </div>
                          <label htmlFor={`radio-${option.id}`}>
                            <p className="p">{option.text}</p>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="survey-footer">
                <div className="button-container">
                  <MyButton
                    endIcon={<ArrowForwardIcon />}
                    variant="contained"
                    color="primary"
                    onClick={() => nextStep()}
                    disabled={!selected}
                  >
                    <Trans>Continue</Trans>
                  </MyButton>
                </div>
              </div>
            </div>
            <div className="survey-poster">
              <img src={POSTER} alt="clutter-free" />
            </div>
            <LangChange />
          </div>
        )}
      </MuiThemeProvider>
    )
  }
)

export default StartSurvey
