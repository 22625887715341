import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import './settings.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'

import { t, Trans } from '@lingui/macro'

import { MyButton } from '../_MyComponents'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import ActionDialog from '../ActionDialog/ActionDialog.js'
import InviteUsers from '../InviteUsers/InviteUsers.js'
import { metabase } from '../../services/host'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import PlanProgress from '../PlanProgress/PlanProgress.js'
import Switch from '@material-ui/core/Switch'
import { MyKeyword } from '../_MyComponents'
import ANALYTICS from '../../assets/MockSettings/mock-analytics.png'
import PERMISSIONS from '../../assets/MockSettings/mock-permissions.png'
import CUSTOMAI from '../../assets/MockSettings/mock-customAI.png'
import INTEGRATIONS from '../../assets/MockSettings/mock-integrations.png'
import Divider from '@mui/material/Divider'

// LANGUAGES
import Flag from 'react-world-flags'
import { default as MuiMI } from '@material-ui/core/MenuItem'
import { langToCountry } from '../../util/langToCountry'
import { i18n } from '@lingui/core'
import { messages as HE } from '../../locales/he/messages'
import { messages as EN } from '../../locales/en/messages'
import { messages as PT } from '../../locales/pt/messages'
import { messages as ES } from '../../locales/es/messages'
import { messages as FR } from '../../locales/fr/messages'

//import ONEDRIVE from '../../assets/onedrive_icon.svg'

const Settings = observer(
  ({
    // from parent
    isOpen,
    setSettingsDialogState,
    language,
    setLanguage,
  }) => {
    const { orgsWorkspacesStore, authenticationStore } = useStore()

    const {
      workspaceUsers,
      removeMember,
      workspaces,
      deleteWorkspace,
      createPaymentLink,
      savePaymentsInfo,
      updatePaymentsPlan,
      cancelPayments,
      organization,
      orgType,
      orgPayment,
      orgPaymentInfo,
      orgPlan,
      featureFlags,
      logo,
      workspaceId, // for analytics iframe
      isAllowed,
      inviteUser,
    } = orgsWorkspacesStore

    const { userState, updateUserState } = authenticationStore

    const [dialogState, setDialogState] = useState()
    const [cancelDialog, setCancelDialog] = useState(false)
    const [switchPlanDialog, setSwitchPlanDialog] = useState(false)
    const [upgradeDialog, setUpgradeDialog] = useState(false)
    const [preventStudioDialog, setPreventStudioDialog] = useState(false)
    const [sidemenuState, setSidemenuState] = React.useState(
      isAllowed(RULES.ADDING_USERS) ? isOpen : 'personal'
    )
    const [deleteWsValidated, setDeleteWsValidated] = React.useState(false)

    //rerenders the app on lang change
    const [_, setLang] = useState('en-US')

    const Paddle = window.Paddle
    const handleSubscribe = async ({ plan, payment }) => {
      if (plan === 'free') {
        setCancelDialog(false)
        await cancelPayments()
      } else if (
        (plan === 'studio-yearly' || plan === 'studio-monthly') &&
        workspaceUsers.length > 3
      ) {
        setPreventStudioDialog('open')
      } else {
        if (orgPlan === 'free') {
          //creating a plan
          handleClose()
          const { url } = await createPaymentLink(plan)
          Paddle.Checkout.open({
            //produt: 22627,
            override: url,
            successCallback: (data, err) => {
              if (err) {
                //do something?
                console.log('successCallback error', err)
              } else {
                setSettingsDialogState('account')
                savePaymentsInfo({ data, payment })
                window.gtag('event', 'conversion', {
                  send_to: 'AW-370002814/-7kwCLzIuJIZEP6Wt7AB',
                })
              }
            },
          })
        } else {
          //changing to a diffrent plan
          await updatePaymentsPlan({ plan, payment })
        }
      }
    }

    const handleContactUs = () => {
      window.open('mailto:hello@tagbox.io')
    }

    const handleClose = () => {
      setSettingsDialogState(null)
    }

    const handleValidation = (e) => {
      if (e.target.value === 'delete workspace') {
        setDeleteWsValidated(true)
      } else {
        setDeleteWsValidated(false)
      }
    }

    const handleDeleteWorkspace = () => {
      setSettingsDialogState(null)
      deleteWorkspace()
    }

    const subscriptionSec = orgType === 'regular' && (
      <div className={'settings-section'}>
        <h2 className={'h2'}>
          <Trans>Subscription</Trans>
        </h2>
        <div className="settings-card-container">
          {/* SOLO PLAN */}
          <div className={`settings-card ${orgPlan === 'free' && 'selected'}`}>
            <div className="empty-state-content">
              <div className="h2">
                <Trans>Solo Plan</Trans>
              </div>
              <div className="es-card-desc">
                <Trans>Free</Trans>
              </div>
            </div>
            <MyButton
              onClick={() => setCancelDialog(true)}
              className="pricing-button"
              variant="contained"
              color="secondary"
              size="small"
              disabled={orgPayment === 'free'}
            >
              <label>
                <Trans>Change plan</Trans>
              </label>
            </MyButton>
          </div>

          {/* STUDIO PLAN */}
          <div
            className={`settings-card ${orgPlan === 'studio' && 'selected'}`}
          >
            <div className="h2">
              <Trans>Studio Plan</Trans>
            </div>
            <div className="es-card-desc">
              <Trans>
                $150/month
                <br />
                ($1800 total)
              </Trans>
            </div>
            <MyButton
              onClick={() =>
                switchPlan({
                  plan: 'studio-yearly',
                  payment: 'studio-yearly',
                })
              }
              className="pricing-button"
              variant="contained"
              color="secondary"
              size="small"
              disabled={orgPayment === 'studio-yearly'}
            >
              <label>
                <Trans>Select Yearly</Trans>
              </label>
            </MyButton>
            <div className="es-card-desc">
              <Trans>
                or
                <br />
                $180/month
              </Trans>
            </div>
            <MyButton
              onClick={() =>
                switchPlan({
                  plan: 'studio-monthly',
                  payment: 'studio-monthly',
                })
              }
              className="pricing-button"
              variant="contained"
              color="secondary"
              size="small"
              disabled={orgPayment === 'studio-monthly'}
            >
              <label>
                <Trans>Select Monthly</Trans>
              </label>
            </MyButton>
          </div>

          {/* COMPANY PLAN */}
          <div
            className={`settings-card ${orgPlan === 'company' && 'selected'}`}
          >
            <div className="h2">
              <Trans>Company Plan</Trans>
            </div>
            <div className="es-card-desc">
              <Trans>
                $250/month
                <br />
                ($3000 total)
              </Trans>
            </div>
            <MyButton
              onClick={() =>
                switchPlan({
                  plan: 'company-yearly',
                  payment: 'company-yearly',
                })
              }
              className="pricing-button"
              variant="contained"
              color="secondary"
              size="small"
              disabled={orgPayment === 'company-yearly'}
            >
              <label>
                <Trans>Select Yearly</Trans>
              </label>
            </MyButton>
            <div className="es-card-desc">
              <Trans>
                or
                <br />
                $300/month
              </Trans>
            </div>
            <MyButton
              onClick={() =>
                switchPlan({
                  plan: 'company-monthly',
                  payment: 'company-monthly',
                })
              }
              className="pricing-button"
              variant="contained"
              color="secondary"
              size="small"
              disabled={orgPayment === 'company-monthly'}
            >
              <label>
                <Trans>Select Monthly</Trans>
              </label>
            </MyButton>
          </div>

          {/* ENTERPRISE PLAN */}
          <div
            className={`settings-card ${
              orgPlan === 'enterprise' && 'selected'
            }`}
          >
            <div className="empty-state-content">
              <div className="h2">
                <Trans>Enterprise Plan</Trans>
              </div>
              {!orgPaymentInfo && (
                <div className="es-card-desc">
                  <Trans>Custom pricing</Trans>
                </div>
              )}
              {!!orgPaymentInfo?.yearly && (
                <div className="es-card-desc">
                  {`$${
                    Math.round((orgPaymentInfo.yearly.price / 12) * 10) / 10
                  }/month`}
                  <br />
                  (${orgPaymentInfo.yearly.price} total)
                </div>
              )}
            </div>
            {!orgPaymentInfo && (
              <MyButton
                onClick={handleContactUs}
                className="pricing-button"
                variant="contained"
                color="secondary"
                size="small"
              >
                <label>
                  <Trans>Contact us</Trans>
                </label>
              </MyButton>
            )}
            {!!orgPaymentInfo?.yearly && (
              <MyButton
                onClick={() =>
                  switchPlan({
                    plan: orgPaymentInfo.yearly.id,
                    payment: 'enterprise-yearly',
                  })
                }
                className="pricing-button"
                variant="contained"
                color="secondary"
                size="small"
                disabled={orgPayment === 'enterprise-yearly'}
              >
                <label>
                  <Trans>Select Yearly</Trans>
                </label>
              </MyButton>
            )}
            {!!orgPaymentInfo?.monthly && !!orgPaymentInfo.yearly && (
              <div className="es-card-desc">
                <Trans>or</Trans>
              </div>
            )}
            {!!orgPaymentInfo?.monthly && (
              <div className="es-card-desc">{`$${orgPaymentInfo.monthly.price}/month`}</div>
            )}
            {!!orgPaymentInfo?.monthly && (
              <MyButton
                onClick={() =>
                  switchPlan({
                    plan: orgPaymentInfo.monthly.id,
                    payment: 'enterprise-monthly',
                  })
                }
                className="pricing-button"
                variant="contained"
                color="secondary"
                size="small"
                disabled={orgPayment === 'enterprise-monthly'}
              >
                <label>
                  <Trans>Select Monthly</Trans>
                </label>
              </MyButton>
            )}
            {!orgPaymentInfo && (
              <div className="es-card-desc">
                <Trans>
                  A custom plan tailored for your requirements. <br />
                  <a
                    target="_blank"
                    href="https://tagbox.io/pricing"
                    rel="noreferrer"
                  >
                    Read more.
                  </a>
                </Trans>
              </div>
            )}
          </div>
        </div>
      </div>
    )

    const planProgressSec = orgType === 'regular' && (
      <div className={'settings-section'}>
        <h2 className={'h2'}>
          <Trans>Plan status</Trans>
        </h2>
        <PlanProgress hasAction={false} />
      </div>
    )

    const deleteWorkspaceSec = (
      <div className={'settings-section'}>
        <h2 className={'h2'}>
          <Trans>Delete workspace</Trans>
        </h2>
        {/* if this is the only WS - prevent delete */}
        {workspaces.length === 1 && (
          <div>
            <div>
              <Trans>
                This is your only workspace.
                <br />
                Please create a new workspace before deleting this one.
              </Trans>
            </div>
          </div>
        )}
        {/* if you have muliple WS - allow delete */}

        <div>
          <Trans>
            Note: deleted workspaces will no longer be available,
            <br />
            including all content and tags added so far.
            <br />
            <br />
            To delete this workspace, please type "delete workspace" below.
          </Trans>
        </div>
        <TextField
          variant="outlined"
          onChange={(e) => handleValidation(e)}
          size="small"
          placeholder={t`type "delete workspace"`}
          className={'delete-workspace-validation'}
        />
        <MyButton
          color={'primary'}
          disabled={!deleteWsValidated}
          variant="contained"
          onClick={handleDeleteWorkspace}
        >
          <Trans>Delete this workspace</Trans>
        </MyButton>
      </div>
    )

    const handleLangChange = (event) => {
      const lang = event.target.value
      if (lang !== language) {
        const messages =
          lang === 'he-IL'
            ? HE
            : lang === 'pt-BR'
            ? PT
            : lang === 'es-ES'
            ? ES
            : lang === 'fr-FR'
            ? FR
            : EN
        // load and activate
        setLanguage(lang)
        updateUserState({ language: lang })
        i18n.load(lang, messages)
        i18n.activate(lang)
        setLang(lang)
      }
    }

    const languageSec = (
      <div className={'settings-section'}>
        <h2 className={'h2'}>
          <Trans>Interface Language</Trans>
        </h2>
        {/* if this is the only WS - prevent delete */}
        <div>
          <div className={'language-wrapper'}>
            <FormControl variant="outlined" className="language-input">
              <Select
                value={language}
                defaultValue={'en-US'}
                label={t`Language`}
                onChange={handleLangChange}
                style={{ fontSize: '13px' }}
                // add custom classname to mui select using classes
              >
                <MuiMI
                  key="en"
                  value="en-US"
                  classes={{ root: 'language-item' }}
                >
                  <p className="p">English</p>
                  <Flag code={langToCountry('en')} />
                </MuiMI>
                <MuiMI
                  key="fr"
                  value="fr-FR"
                  classes={{ root: 'language-item' }}
                >
                  <p className="p">Français</p>
                  <Flag code={langToCountry('fr')} />
                </MuiMI>
                <MuiMI
                  key="es"
                  value="es-ES"
                  classes={{ root: 'language-item' }}
                >
                  <p className="p">Español</p>
                  <Flag code={langToCountry('es')} />
                </MuiMI>
                <MuiMI
                  key="pt"
                  value="pt-BR"
                  classes={{ root: 'language-item' }}
                >
                  <p className="p">Português</p>
                  <Flag code={langToCountry('pt')} />
                </MuiMI>
                {/* Hebrew is mostly for QA due to lack of RTL support */}
                {/* <MuiMI
                  key="he"
                  value="he-IL"
                  classes={{ root: 'language-item' }}
                >
                  <p className="p">עברית</p>
                  <Flag code={langToCountry('he')} />
                </MuiMI> */}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    )

    const captionsSec = (
      <div className={'settings-section'}>
        <h2 className={'h2'}>
          <Trans>Video Captions</Trans>
        </h2>
        {/* if this is the only WS - prevent delete */}
        <div>
          <div className={'captions-wrapper'}>
            <FormControl variant="outlined" className="captions-input">
              <Select
                value={userState?.transcription?.captionOpen || false}
                defaultValue={false}
                //label={t`Captions`}
                size="small"
                variant="standard"
                onChange={(e) => {
                  if (
                    e.target.value !== userState?.transcription?.captionOpen
                  ) {
                    updateUserState({
                      'transcription.captionOpen': e.target.value,
                    })
                  }
                }}
                style={{ fontSize: '13px' }}
                // add custom classname to mui select using classes
              >
                <MuiMI
                  key="off"
                  value={false}
                  classes={{ root: 'captions-item' }}
                >
                  <p className="p">Off</p>
                </MuiMI>
                <MuiMI
                  key="on"
                  value={true}
                  classes={{ root: 'captions-item' }}
                >
                  <p className="p">On</p>
                </MuiMI>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    )

    const analyticsSec = (
      <>
        {!featureFlags?.analytics?.enabled && (
          <div className="settings-content mock">
            <div className="mock-title">
              <h1 className="h1">
                <Trans>Analytics</Trans>
              </h1>
              <MyKeyword text={t`Enterprise feature`} disabled />
            </div>

            <p className="p light">
              <Trans>Get insight into user activity and asset usage.</Trans>
            </p>
            <div className="automation-container">
              <div className="flex-row automation-toggle-section">
                <div className="flex-row">
                  <Switch
                    //className={"share-toggle"}
                    checked={false}
                    color="primary"
                    disabled
                  />
                  <div className="flex-col FMP">
                    <h2 className="h2">
                      <Trans>
                        Analytics isn't part of your current plan. Contact us at
                        hello@tagbox.io to inquire about an upgrade.
                      </Trans>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <img className="mock-image" src={ANALYTICS} alt="Analytics" />
          </div>
        )}
        {featureFlags?.analytics?.enabled && (
          <div className="analytics-wrapper flex-row">
            <iframe
              title="Workspace analytics"
              //src={metabase + "?date=past30days~&workspace=" + workspaceId + "#hide_parameters=workspace"}
              src={
                metabase +
                '?workspace=' +
                workspaceId +
                '#hide_parameters=workspace'
              }
              frameborder="0"
              width="100%"
              height="100%"
              allowtransparency
            />
          </div>
        )}
      </>
    )

    const permissionsSec = (
      <div className="settings-content mock">
        <div className="mock-title">
          <h1 className="h1">
            <Trans>Custom permissions</Trans>
          </h1>
          <MyKeyword text={t`Enterprise feature`} disabled />
        </div>
        <p className="p light">
          <Trans>
            Create new roles and have full control over who can do what.{' '}
            <a
              href="https://tagbox.notion.site/Roles-permissions-47ebe2fccf8b4eb4a122c335433e4484"
              target="_blank"
              rel="noreferrer"
              className="more-link"
            >
              Learn more
            </a>
          </Trans>
        </p>
        <div className="automation-container">
          <div className="flex-row automation-toggle-section">
            <div className="flex-row">
              <Switch
                //className={"share-toggle"}
                checked={orgPlan === 'enterprise'}
                color="secondary"
              />
              <div className="flex-col FMP">
                <h2 className="h2">
                  {orgPlan === 'enterprise' ? (
                    <Trans>
                      Custom permissions is currently only available via our
                      support team. <br />
                      Contact hello@tagbox.io to apply changes to your roles &
                      permissions.
                    </Trans>
                  ) : (
                    <Trans>
                      Custom permissions isn't part of your current plan.
                      <br />
                      Contact us at hello@tagbox.io to inquire about an upgrade.
                    </Trans>
                  )}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <img className="mock-image" src={PERMISSIONS} alt="Permissions" />
      </div>
    )

    const customAISec = (
      <div className="settings-content mock">
        <div className="mock-title">
          <h1 className="h1">
            <Trans>Custom AI training</Trans>
          </h1>
          <MyKeyword text={t`Enterprise feature`} disabled />
        </div>
        <p className="p light">
          <Trans>
            Save tagging time by training our AI to identify specific objects
            and scenes.
          </Trans>
        </p>
        <div className="automation-container">
          <div className="flex-row automation-toggle-section">
            <div className="flex-row">
              <Switch
                //className={"share-toggle"}
                checked={false}
                color="primary"
                disabled
              />
              <div className="flex-col FMP">
                <h2 className="h2">
                  <Trans>
                    Custom AI training isn't part of your current plan. Contact
                    us at hello@tagbox.io to inquire about an upgrade.
                  </Trans>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <img className="mock-image" src={CUSTOMAI} alt="Custom AI" />
      </div>
    )

    const integrationsSec = (
      <div className="settings-content mock">
        <div className="mock-title">
          <h1 className="h1">
            <Trans>Integrations</Trans>
          </h1>
          <MyKeyword text={t`Enterprise feature`} disabled />
        </div>
        <p className="p light">
          <Trans>
            Connect Tagbox to select design tools, access your assets directly,
            <br />
            and manage your designs automatically in Tagbox.io for a smoother
            workflow.
          </Trans>
        </p>
        <div className="automation-container">
          <div className="flex-row automation-toggle-section">
            <div className="flex-row">
              <Switch
                //className={"share-toggle"}
                checked={false}
                color="primary"
                disabled
              />
              <div className="flex-col FMP">
                <h2 className="h2">
                  <Trans>
                    Integrations aren't part of your current plan. Contact us at
                    hello@tagbox.io to inquire about an upgrade.
                  </Trans>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <img className="mock-image" src={INTEGRATIONS} alt="Custom AI" />
      </div>
    )

    const apiSec = (
      <div className="settings-content mock">
        <div className="mock-title">
          <h1 className="h1">
            <Trans>API</Trans>
          </h1>
          <MyKeyword text={t`Enterprise feature`} disabled />
        </div>
        <p className="p light">
          <Trans>
            Integrate Tagbox.io with your existing tools using our API. Automate
            workflows, sync data, and more.
          </Trans>
        </p>
        <div className="automation-container">
          <div className="flex-row automation-toggle-section">
            <div className="flex-row">
              <Switch
                //className={"share-toggle"}
                checked={false}
                color="primary"
                disabled
              />
              <div className="flex-col FMP">
                <h2 className="h2">
                  <Trans>
                    API isn't part of your current plan. Contact us at
                    hello@tagbox.io to inquire about an upgrade.
                  </Trans>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="settings-text-wrapper">
          <p className="p">
            <Trans>
              Our API is currently in Beta. To explore what’s possible, visit
              our{' '}
              <a
                href="https://api-dev.tagbox.io/docs/#/"
                target="_blank"
                rel="noreferrer"
              >
                API page
              </a>{' '}
              or contact support for access.
            </Trans>
          </p>
        </div>
      </div>
    )

    const switchPlan = (newPlan) => {
      if (orgPlan === 'free') {
        handleSubscribe(newPlan)
      } else {
        setSwitchPlanDialog(newPlan)
      }
    }

    const confirmSwitchPlan = (newPlan) => {
      handleSubscribe(newPlan)
      setSwitchPlanDialog(false)
    }

    return (
      <Dialog
        onClose={handleClose}
        fullScreen
        maxWidth={'lg'}
        aria-labelledby="simple-dialog-title"
        open={!!isOpen}
        className="settings-dialog"
      >
        <div className="settings-wrapper">
          <div className="settings-top-bar">
            {!!organization && logo && (
              <img
                className="logo"
                src={logo}
                alt="Logo"
                onClick={handleClose}
                style={{ cursor: 'pointer' }}
              />
            )}
            <IconButton
              className="close-dialog-setting"
              onClick={handleClose}
              component="span"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="settings-main">
            <div className="settings-sidebar">
              <h1 className="h1">
                <Trans>Settings</Trans>
              </h1>
              <List
                className="settings-nav"
                component="nav"
                aria-labelledby="nested-list-subheader"
                sx={{
                  flexGrow: 1,
                  maxWidth: 400,
                  overflowY: 'auto',
                }}
              >
                {isAllowed(RULES.ADDING_USERS) && (
                  <ListItemButton
                    selected={sidemenuState === 'workspace'}
                    className="settings-menu-button"
                  >
                    <ListItemText
                      primary={t`Workspace`}
                      onClick={() => setSidemenuState('workspace')}
                    />
                  </ListItemButton>
                )}
                {isAllowed(RULES.CHANGE_ORG_PLAN) && (
                  <ListItemButton
                    selected={sidemenuState === 'account'}
                    className="settings-menu-button"
                  >
                    <ListItemText
                      primary={t`Account`}
                      onClick={() => setSidemenuState('account')}
                    />
                  </ListItemButton>
                )}

                <ListItemButton
                  selected={sidemenuState === 'personal'}
                  className="settings-menu-button"
                >
                  <ListItemText
                    primary={t`Preferences`}
                    onClick={() => setSidemenuState('personal')}
                  />
                </ListItemButton>

                <Divider />

                {isAllowed(RULES.CHANGE_ORG_PLAN) && (
                  <ListItemButton
                    selected={sidemenuState === 'analytics'}
                    className="settings-menu-button"
                  >
                    <ListItemText
                      primary={t`Analytics`}
                      onClick={() => setSidemenuState('analytics')}
                    />
                  </ListItemButton>
                )}

                {isAllowed(RULES.ADDING_USERS) && (
                  <ListItemButton
                    selected={sidemenuState === 'permissions'}
                    className="settings-menu-button"
                  >
                    <ListItemText
                      primary={t`Custom permissions`}
                      onClick={() => setSidemenuState('permissions')}
                    />
                  </ListItemButton>
                )}

                {isAllowed(RULES.CHANGE_ORG_PLAN) && (
                  <ListItemButton
                    selected={sidemenuState === 'customAI'}
                    className="settings-menu-button"
                  >
                    <ListItemText
                      primary={t`Custom AI training`}
                      onClick={() => setSidemenuState('customAI')}
                    />
                  </ListItemButton>
                )}

                {isAllowed(RULES.CHANGE_ORG_PLAN) && (
                  <ListItemButton
                    selected={sidemenuState === 'integrations'}
                    className="settings-menu-button"
                  >
                    <ListItemText
                      primary={t`Integrations`}
                      onClick={() => setSidemenuState('integrations')}
                    />
                  </ListItemButton>
                )}

                {isAllowed(RULES.CHANGE_ORG_PLAN) && (
                  <ListItemButton
                    selected={sidemenuState === 'api'}
                    className="settings-menu-button"
                  >
                    <ListItemText
                      primary={t`API`}
                      onClick={() => setSidemenuState('api')}
                    />
                  </ListItemButton>
                )}

                {false && (
                  <ListItemButton
                    selected={sidemenuState === 'import'}
                    className="settings-menu-button"
                  >
                    <ListItemText
                      primary={t`Import`}
                      onClick={() => setSidemenuState('import')}
                    />
                  </ListItemButton>
                )}
              </List>
            </div>

            <div className="settings-content-wrapper">
              {/* ACCOUNT */}

              {sidemenuState === 'account' && (
                <div className="settings-content">
                  <h1 className="h1">
                    <Trans>Account settings</Trans>
                  </h1>

                  {subscriptionSec}

                  {planProgressSec}

                  <div
                    className="read-more"
                    onClick={() =>
                      window.open('https://www.tagbox.io/pricing', '_blank')
                    }
                  >
                    <Trans>Read more about our pricing</Trans>
                  </div>
                </div>
              )}

              {/* WORKSPACE */}

              {sidemenuState === 'workspace' && (
                <div className="settings-content">
                  <h1 className="h1">
                    <Trans>Workspace settings</Trans>
                  </h1>

                  <InviteUsers
                    inviteFunction={inviteUser}
                    users={workspaceUsers}
                    setUpgradeDialog={setUpgradeDialog}
                    setDialogState={setDialogState}
                    setSidemenuState={setSidemenuState}
                  />

                  {workspaces.length > 1 && deleteWorkspaceSec}
                </div>
              )}

              {/* PERSONAL PREFERENCES */}

              {sidemenuState === 'personal' && (
                <div className="settings-content">
                  <h1 className="h1">
                    <Trans>Preferences</Trans>
                  </h1>

                  {languageSec}

                  {captionsSec}
                </div>
              )}

              {/* ANALYTICS */}

              {sidemenuState === 'analytics' && <>{analyticsSec}</>}

              {sidemenuState === 'permissions' && <>{permissionsSec}</>}

              {sidemenuState === 'customAI' && <>{customAISec}</>}

              {sidemenuState === 'integrations' && <>{integrationsSec}</>}

              {sidemenuState === 'api' && <>{apiSec}</>}
            </div>
          </div>
        </div>
        <ActionDialog
          actionName={t`remove subscription`}
          action={() => handleSubscribe({ plan: 'free', payment: 'free' })}
          cancel={() => setCancelDialog(false)}
          question={t`Cancel your subscription?`}
          comment={t`You're about to permanently cancel your subscription. This might
              lead to a loss of data.`}
          color={'warning'}
          isOpen={cancelDialog}
        />
        <ActionDialog
          actionName={t`Change plan`}
          action={() => confirmSwitchPlan(switchPlanDialog)}
          cancel={() => setSwitchPlanDialog(false)}
          question={t`Change your subscription?`}
          comment={t`Switching your subscription plan will take effect immediately and
              may result in a new charge.`}
          isOpen={switchPlanDialog}
        />
        <ActionDialog
          actionName={t`Upgrade`}
          action={() => {
            setUpgradeDialog(false)
            setSidemenuState('account')
          }}
          cancel={() => setUpgradeDialog(false)}
          question={t`Upgrade plan?`}
          comment={t`You've used all of the users in current plan. Would you like to
              upgrade to the Company plan?`}
          //color={"warning"}
          isOpen={upgradeDialog}
        />
        <ActionDialog
          actionName={t`Okay`}
          action={() => {
            setPreventStudioDialog(false)
          }}
          //cancel={() => setUpgradeDialog(false)}
          question={t`Users acceed plan limit`}
          comment={t`The Studio plan is limited to 3 users. Since you already have more
              than that, please select a more advanced plan.`}
          //color={"warning"}
          isOpen={preventStudioDialog}
        />
        {dialogState?.username && (
          <div className="remove-dialog-container">
            <div className="remove-dialog">
              <div className="remove-dialog-title">
                <Trans>Remove member?</Trans>
              </div>
              <div className="username-remove-title">
                {dialogState.username}
              </div>
              <MyButton
                variant="contained"
                color="primary"
                className="remove-member-button"
                onClick={() => {
                  removeMember(dialogState)
                  setDialogState('')
                }}
              >
                <Trans>Remove</Trans>
              </MyButton>
              <MyButton onClick={() => setDialogState('')}>
                <Trans>Cancel</Trans>
              </MyButton>
            </div>
          </div>
        )}
      </Dialog>
    )
  }
)

export default Settings
